import { SETUP } from './actions';
import { OrganizationConfig, OrganizationActionProps } from 'types/organization';
export const initialState: OrganizationConfig = {
  organization: null
};

const organizationConfig = (state = initialState, action: OrganizationActionProps) => {
  switch (action.type) {
    case SETUP: {
      const { organization } = action.payload!;
      return {
        ...state,
        organization
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default organizationConfig;
