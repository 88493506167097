import { useContext } from 'react';
import CapsuleContext from 'contexts/CapsuleContext';

const useCapsule = () => {
  const context = useContext(CapsuleContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useCapsule;
