import { ClusterOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import useCapsule from 'hooks/useCapsule';
import iconSelected from 'utils/icon';

const NavigationRequester = () => {
  const { capsules } = useCapsule();

  let menuItems: any[] | undefined;

  if (capsules !== null && capsules !== undefined) {
    const capsulesFiltered = capsules.filter((capsule) => capsule.external === true);

    menuItems = capsulesFiltered.map((c) => ({
      id: c.url,
      title: c.name,
      type: 'item',
      url: `process/requester/${c.url}/in-progress`,
      icon: iconSelected(c.icon)
    }));
  } else {
    menuItems = [];
  }

  if (menuItems.length > 3) {
    menuItems = [
      {
        id: 'structureCapsule',
        title: 'Módulos',
        type: 'collapse',
        icon: ClusterOutlined,
        children: menuItems
      }
    ];
  }

  const requesterArrayStart = [
    {
      id: 'process/my/in-progress',
      title: 'Meus processos',
      type: 'item',
      url: 'process/my/in-progress',
      icon: UserOutlined
    }
  ];

  let requesterArrayEnd = [
    {
      id: 'organogram',
      title: 'Organograma',
      type: 'item',
      url: 'organization/manager/organogram',
      icon: ClusterOutlined
    },
    {
      id: 'organization',
      title: 'Organização',
      type: 'item',
      url: 'organization/account/details',
      icon: HomeOutlined
    }
  ];

  let requesterArrayFinish;

  if (menuItems && menuItems.length > 0) {
    requesterArrayFinish = requesterArrayStart.concat(menuItems, requesterArrayEnd);
  } else {
    requesterArrayFinish = requesterArrayStart.concat(requesterArrayEnd);
  }

  return requesterArrayFinish;
};

export default NavigationRequester;
