import React, { createContext, useEffect, useReducer } from 'react';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { AuthProps, JWTContextType, JuridicPerson, PhysicalPerson, PersonProfile } from 'types/auth';
import toast from 'utils/ToastNotistack';
import useOrganization from 'hooks/useOrganization';

const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  person: null
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { organization } = useOrganization();

  const clearLocalStorage = () => {
    window.localStorage.removeItem('0paper-auth-headers');
    dispatch({ type: LOGOUT });
  };

  const setPerson = (person: PersonProfile) => {
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        person
      }
    });
  };

  const verifyToken = async () => {
    try {
      const response = await axios.get('/auth/validate_token');
      const person = response.data;

      setPerson(person);
    } catch (error) {
      clearLocalStorage();
    }
  };

  useEffect(() => {
    const authHeaders = window.localStorage.getItem('0paper-auth-headers');

    authHeaders ? verifyToken() : clearLocalStorage();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/auth/sign_in', { email, password });
    const person = response.data;

    setPerson(person);
  };

  const registerPf = async (params: PhysicalPerson) => {
    await axios.post('/v1/physical_people', {
      organization_id: organization?.id,
      name: params.name,
      cpf: params.cpf,
      function: params.function,
      date_of_birth: params.dateOfBirth,
      gender: params.gender,
      user_attributes: {
        email: params.email,
        phone: params.phone,
        password: params.password,
        password_confirmation: params.passwordConfirmation
      },
      address_attributes: {
        zipcode: params.zipcode,
        street: params.street,
        neighborhood: params.neighborhood,
        city: params.city,
        state: params.state,
        number: params.number,
        complement: params.complement
      }
    });

    clearLocalStorage();
  };

  const registerPj = async (params: JuridicPerson) => {
    await axios.post('/v1/juridic_people', {
      organization_id: organization?.id,
      corporate_name: params.corporateName,
      cnpj: params.cnpj,
      fantasy_name: params.fantasyName,
      primary_contact_name: params.primaryContactName,
      user_attributes: {
        email: params.email,
        phone: params.phone,
        password: params.password,
        password_confirmation: params.passwordConfirmation
      },
      address_attributes: {
        zipcode: params.zipcode,
        street: params.street,
        neighborhood: params.neighborhood,
        city: params.city,
        state: params.state,
        number: params.number,
        complement: params.complement
      }
    });

    clearLocalStorage();
  };

  const logout = async (customMessage?: string) => {
    const defaultMessage = 'Logout concluído com sucesso! Até logo! ;)';
    const message = customMessage || defaultMessage;

    try {
      const authHeaders = window.localStorage.getItem('0paper-auth-headers');

      authHeaders && (await axios.delete('/auth/sign_out'));
      clearLocalStorage();
    } catch (error) {
      clearLocalStorage();
    }

    toast(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  };

  const resetPassword = async (email: string) => {
    await axios.post('/auth/password', {
      email,
      redirect_url: `${process.env.REACT_APP_URL_FRONTEND}/reset-password`
    });
  };

  const resetPasswordEmail = async (password: string, passwordConfirmation: string) => {
    const params = new URLSearchParams(window.location.href);

    await axios.put(
      '/auth/password',
      {
        password,
        password_confirmation: passwordConfirmation
      },
      {
        headers: {
          'access-token': params.get('token'),
          client: params.get('client'),
          uid: params.get('uid'),
          expiry: params.get('expiry'),
          'token-type': 'Bearer'
        }
      }
    );
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, registerPf, registerPj, resetPasswordEmail, resetPassword, setPerson }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
