import { Typography, Stack, CardMedia } from '@mui/material';
import { DropzopType } from 'types/dropzone';
import UploadCover from 'assets/images/upload/upload.svg';

export default function PlaceholderContent({ type }: { type?: string }) {
  return (
    <>
      {type !== DropzopType.standard && (
        <Stack alignItems="center" justifyContent="center" direction={{ xs: 'column', md: 'row' }}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <CardMedia component="img" image={UploadCover} sx={{ width: 100 }} />
          </Stack>
          <Stack sx={{ p: 3 }} spacing={1}>
            <Typography variant="h5">Arraste e solte ou selecione os documentos</Typography>

            <Typography color="secondary">Solte os documentos aqui ou clique para selecionar da sua máquina</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
