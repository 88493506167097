import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Link } from '@mui/material';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import useConfig from 'hooks/useConfig';
import { LAYOUT_CONST } from 'types/config';
import { APP_DEFAULT_PATH } from 'config';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'assets/images/partners/publicsoft.png';
import Logo02 from 'assets/images/partners/publicsoft02.png';

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : '60px',
        width: isHorizontal ? { xs: '100%', lg: '424px' } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
    >
      {open && (
        <Link variant="h1" component={RouterLink} to={APP_DEFAULT_PATH} color="text.primary" underline="none">
          <img src={Logo} alt="PublicSoft" width="210" height="45" />
        </Link>
      )}

      {!open && (
        <Link variant="h1" component={RouterLink} to={APP_DEFAULT_PATH} color="text.primary" underline="none">
          <img src={Logo02} alt="PublicSoft" width="40" height="40" />
        </Link>
      )}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
