import axios from 'axios';

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosServices.interceptors.request.use(
  (request) => {
    const authHeaders = window.localStorage.getItem('0paper-auth-headers');
    const authHeadersJson = authHeaders && JSON.parse(authHeaders);

    if (authHeadersJson) {
      request.headers['access-token'] = authHeadersJson['access-token'];
      request.headers['client'] = authHeadersJson['client'];
      request.headers['uid'] = authHeadersJson['uid'];
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => {
    if (response.headers['access-token']) {
      const authHeaders = {
        'access-token': response.headers['access-token'],
        client: response.headers['client'],
        uid: response.headers['uid']
      };

      window.localStorage.setItem('0paper-auth-headers', JSON.stringify(authHeaders));
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export default axiosServices;
