import { IconButton } from '@mui/material';
import { BulbOutlined } from '@ant-design/icons';
import useConfig from 'hooks/useConfig';
import { ThemeMode } from 'types/config';

const DarkMode = () => {
  const { onChangeMode, mode } = useConfig();

  function handleModeChange(mode: ThemeMode) {
    if (mode === 'light') {
      onChangeMode('dark');
    } else {
      onChangeMode('light');
    }
  }

  return (
    <IconButton onClick={() => handleModeChange(mode)}>
      <BulbOutlined />
    </IconButton>
  );
};

export default DarkMode;
