import { useTheme } from '@mui/material/styles';
import { Box, Button, Container, CardMedia, Grid, Link, Typography, Stack, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import AnimateButton from 'components/@extended/AnimateButton';
import { EyeOutlined } from '@ant-design/icons';
import imgbg from 'assets/images/landing/open-process.png';
import { useNavigate } from 'react-router-dom';
import useOrganization from 'hooks/useOrganization';

const Services = () => {
  const theme = useTheme();
  const { organization } = useOrganization();
  const navigate = useNavigate();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.secondary[800],
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '80%',
          bottom: 0,
          left: 0,
          background: `linear-gradient(180deg, transparent, ${
            theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.secondary[800]
          })`
        }
      }}
    >
      {!matchDownMd && <CardMedia component="img" image={imgbg} sx={{ width: 'auto', position: 'absolute', top: 0, right: 0 }} />}
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
          sx={{
            position: 'relative',
            zIndex: 1,
            pt: 18.75,
            pb: 10
          }}
        >
          <Grid item xs={12} md={12}>
            <motion.div
              initial={{ opacity: 0, translateY: 550 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{
                type: 'spring',
                stiffness: 150,
                damping: 30
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: theme.palette.common.white
                }}
              >
                <Box component="div">{organization?.name}</Box>
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={12}>
            <motion.div
              initial={{ opacity: 0, translateY: 550 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{
                type: 'spring',
                stiffness: 150,
                damping: 30
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: theme.palette.common.white
                }}
              >
                <Box component="div">Governo digital, zero papel!</Box>
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={12}>
            <motion.div
              initial={{ opacity: 0, translateY: 550 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{
                type: 'spring',
                stiffness: 150,
                damping: 30,
                delay: 0.4
              }}
            >
              <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <AnimateButton>
                  <Button component={Link} size="large" color="primary" variant="outlined" onClick={() => navigate('/login')}>
                    Abrir processo
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button
                    component={Link}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<EyeOutlined style={{ fontSize: '1.15rem' }} />}
                    onClick={() => navigate('/dashboard/transparency')}
                  >
                    Transparência
                  </Button>
                </AnimateButton>
              </Stack>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
