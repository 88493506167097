import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const OrganogramManager = Loadable(lazy(() => import('pages/extra-pages/manageOrganogram')));
const Organogram = Loadable(lazy(() => import('sections/organogram')));

const Departments = Loadable(lazy(() => import('pages/extra-pages/departments')));
const DepartmentCreate = Loadable(lazy(() => import('pages/extra-pages/departments/new')));
const DepartmentManager = Loadable(lazy(() => import('pages/extra-pages/departments/manager')));
const DepartmentTabDetails = Loadable(lazy(() => import('sections/departments/manager/TabDetails')));
const DepartmentTabEdit = Loadable(lazy(() => import('sections/departments/manager/TabEdit')));
const DepartmentTabOrganogram = Loadable(lazy(() => import('sections/departments/manager/TabOrganogram')));
const DepartmentTabServers = Loadable(lazy(() => import('sections/departments/manager/TabServers')));
const DepartmentsActived = Loadable(lazy(() => import('sections/departments/actived')));
const DepartmentsDisabled = Loadable(lazy(() => import('sections/departments/disabled')));
const DepartmentManagerDisabled = Loadable(lazy(() => import('pages/extra-pages/departments/managerDisabled')));
const DepartmentDisabled = Loadable(lazy(() => import('sections/departments/managerDisabled/TabDetails')));

const Modules = Loadable(lazy(() => import('pages/extra-pages/modules')));
const ModuleCreate = Loadable(lazy(() => import('pages/extra-pages/modules/new')));
const ModuleManager = Loadable(lazy(() => import('pages/extra-pages/modules/manager')));
const ModuleTabDetails = Loadable(lazy(() => import('sections/modules/manager/TabDetails')));
const ModuleTabEdit = Loadable(lazy(() => import('sections/modules/manager/TabEdit')));

const Users = Loadable(lazy(() => import('pages/extra-pages/users')));

const Servers = Loadable(lazy(() => import('sections/users/TabServer')));
const ServerManager = Loadable(lazy(() => import('pages/extra-pages/users/serverManager')));
const ServerTabDetails = Loadable(lazy(() => import('sections/users/servers/TabDetails')));
const ServerTabEdit = Loadable(lazy(() => import('sections/users/servers/TabEdit')));
const ServerTabPermission = Loadable(lazy(() => import('sections/users/servers/TabPermission')));
const ServerTabDepartments = Loadable(lazy(() => import('sections/users/servers/TabDepartments')));
const ServersDisabled = Loadable(lazy(() => import('sections/users/TabServerDisabled')));
const ServerCreate = Loadable(lazy(() => import('pages/extra-pages/users/newServer')));
const ServerManagerDisabled = Loadable(lazy(() => import('pages/extra-pages/users/ServerManagerDisabled')));
const ServerDisabled = Loadable(lazy(() => import('sections/users/ServerManagerDisabled/TabDetails')));

const PhysicalPeople = Loadable(lazy(() => import('sections/users/TabPhysicalPeople')));
const JuridicPeople = Loadable(lazy(() => import('sections/users/TabJuridicPeople')));
const Supports = Loadable(lazy(() => import('sections/users/TabSupports')));
const SupportCreate = Loadable(lazy(() => import('pages/extra-pages/users/newSupport')));

const RequesterManager = Loadable(lazy(() => import('pages/extra-pages/users/requesterManager')));
const RequesterTabDetails = Loadable(lazy(() => import('sections/users/pf/TabDetails')));
const RequesterPjManager = Loadable(lazy(() => import('pages/extra-pages/users/requesterPjManager')));
const RequesterPjTabDetails = Loadable(lazy(() => import('sections/users/pj/TabDetails')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

const OrganizationProfile = Loadable(lazy(() => import('pages/extra-pages/organization')));
const OrganizationTabDetails = Loadable(lazy(() => import('sections/organization/account/TabDetails')));
const OrganizationTabEdit = Loadable(lazy(() => import('sections/organization/account/TabEdit')));

const UserProfile = Loadable(lazy(() => import('pages/extra-pages/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/user/TabPersonal')));
const UserTabPj = Loadable(lazy(() => import('sections/user/TabPj')));
const UserTabPassword = Loadable(lazy(() => import('sections/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/user/TabSettings')));
const UserTabCertificate = Loadable(lazy(() => import('sections/user/TabCertificate')));

const ProcessDepartment = Loadable(lazy(() => import('pages/extra-pages/process/department/')));
const ProcessDepartmentInProgress = Loadable(lazy(() => import('pages/extra-pages/process/department/inProgress')));
const ProcessDepartmentToSign = Loadable(lazy(() => import('pages/extra-pages/process/department/toSign')));
const ProcessDepartmentSent = Loadable(lazy(() => import('pages/extra-pages/process/department/sent')));
const ProcessDepartmentFavorites = Loadable(lazy(() => import('pages/extra-pages/process/department/favorites')));
const ProcessDepartmentArchived = Loadable(lazy(() => import('pages/extra-pages/process/department/archived')));
const ProcessDepartmentDeadlineControl = Loadable(lazy(() => import('pages/extra-pages/process/department/deadlineControl')));

const ProcessInMyCare = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/')));
const ProcessInMyCareInProgress = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/inProgress')));
const ProcessInMyCareToSign = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/toSign')));
const ProcessInMyCareSent = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/sent')));
const ProcessInMyCareFavorites = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/favorites')));
const ProcessInMyCareArchived = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/archived')));
const ProcessInMyCareDeadlineControl = Loadable(lazy(() => import('pages/extra-pages/process/in-my-care/deadlineControl')));

const ProcessMy = Loadable(lazy(() => import('pages/extra-pages/process/my/')));
const ProcessMyInProgress = Loadable(lazy(() => import('pages/extra-pages/process/my/inProgress')));
const ProcessMyToSign = Loadable(lazy(() => import('pages/extra-pages/process/my/toSign')));
const ProcessMyFavorites = Loadable(lazy(() => import('pages/extra-pages/process/my/favorites')));
const ProcessMyFinished = Loadable(lazy(() => import('pages/extra-pages/process/my/finished')));
const ProcessMyDeadlineControl = Loadable(lazy(() => import('pages/extra-pages/process/my/deadlineControl')));

const ProcessRequester = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/')));
const ProcessRequesterInProgress = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/inProgress')));
const ProcessRequesterToSign = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/toSign')));
const ProcessRequesterFavorites = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/favorites')));
const ProcessRequesterFinished = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/finished')));
const ProcessRequesterDeadlineControl = Loadable(lazy(() => import('pages/extra-pages/process/module/requester/deadlineControl')));

const ProcessDepartmentModule = Loadable(lazy(() => import('pages/extra-pages/process/module/server')));
const ProcessDepartmentModuleInProgress = Loadable(lazy(() => import('pages/extra-pages/process/module/server/inProgress')));
const ProcessDepartmentModuleToSign = Loadable(lazy(() => import('pages/extra-pages/process/module/server/toSign')));
const ProcessDepartmentModuleSent = Loadable(lazy(() => import('pages/extra-pages/process/module/server/sent')));
const ProcessDepartmentModuleFavorites = Loadable(lazy(() => import('pages/extra-pages/process/module/server/favorites')));
const ProcessDepartmentModuleArchived = Loadable(lazy(() => import('pages/extra-pages/process/module/server/archived')));
const ProcessDepartmentModuleDeadlineControl = Loadable(lazy(() => import('pages/extra-pages/process/module/server/deadlineControl')));

const ProcessCreate = Loadable(lazy(() => import('pages/extra-pages/process/new')));
const ProcessShow = Loadable(lazy(() => import('pages/extra-pages/process/show')));

const SubjectList = Loadable(lazy(() => import('pages/extra-pages/subject/list')));
const SubjectCreate = Loadable(lazy(() => import('pages/extra-pages/subject/new')));
const SubjectManager = Loadable(lazy(() => import('pages/extra-pages/subject/manager')));
const SubjectTabDetails = Loadable(lazy(() => import('pages/extra-pages/subject/manager/TabDetails')));
const SubjectTabEdit = Loadable(lazy(() => import('pages/extra-pages/subject/manager/TabEdit')));
const SubjectTabMandatoryDocs = Loadable(lazy(() => import('pages/extra-pages/subject/manager/TabMandatoryDocs')));

const MandatoryDocList = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/list')));
const MandatoryDocCreate = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/new')));
const MandatoryDocManager = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/manager')));
const MandatoryDocTabDetails = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/manager/TabDetails')));
const MandatoryDocTabEdit = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/manager/TabEdit')));
const MandatoryDocTabSubjects = Loadable(lazy(() => import('pages/extra-pages/mandatory-doc/manager/TabSubjects')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '*',
          element: <MaintenanceError />
        },
        {
          path: 'departments',
          children: [
            {
              path: 'new',
              element: <DepartmentCreate />
            },
            {
              path: 'list',
              element: <Departments />,
              children: [
                {
                  path: 'actived',
                  element: <DepartmentsActived />
                },
                {
                  path: 'disabled',
                  element: <DepartmentsDisabled />
                }
              ]
            },
            {
              path: 'manager',
              element: <DepartmentManager />,
              children: [
                {
                  path: 'show/:departmentId',
                  element: <DepartmentTabDetails />
                },
                {
                  path: 'edit/:departmentId',
                  element: <DepartmentTabEdit />
                },
                {
                  path: 'organogram/:departmentId',
                  element: <DepartmentTabOrganogram />
                },
                {
                  path: 'servers/:departmentId',
                  element: <DepartmentTabServers />
                }
              ]
            },
            {
              path: 'manager',
              element: <DepartmentManagerDisabled />,
              children: [
                {
                  path: 'disabled/:departmentId',
                  element: <DepartmentDisabled />
                }
              ]
            }
          ]
        },
        {
          path: 'modules',
          children: [
            {
              path: 'new',
              element: <ModuleCreate />
            },
            {
              path: 'manager',
              element: <ModuleManager />,
              children: [
                {
                  path: 'show/:moduleId',
                  element: <ModuleTabDetails />
                },
                {
                  path: 'edit/:moduleId',
                  element: <ModuleTabEdit />
                }
              ]
            },
            {
              path: 'list',
              element: <Modules />
            }
          ]
        },
        {
          path: 'users',
          element: <Users />,
          children: [
            {
              path: 'servers',
              element: <Servers />
            },
            {
              path: 'pf',
              element: <PhysicalPeople />
            },
            {
              path: 'pj',
              element: <JuridicPeople />
            },
            {
              path: 'supports',
              element: <Supports />
            },
            {
              path: 'disabled',
              element: <ServersDisabled />
            }
          ]
        },
        {
          path: 'user',
          children: [
            {
              path: 'server',
              children: [
                {
                  path: 'manager',
                  element: <ServerManager />,
                  children: [
                    {
                      path: 'show/:serverId',
                      element: <ServerTabDetails />
                    },
                    {
                      path: 'edit/:serverId',
                      element: <ServerTabEdit />
                    },
                    {
                      path: 'permission/:serverId',
                      element: <ServerTabPermission />
                    },
                    {
                      path: 'departments/:serverId',
                      element: <ServerTabDepartments />
                    }
                  ]
                },
                {
                  path: 'manager',
                  element: <ServerManagerDisabled />,
                  children: [
                    {
                      path: 'disabled/:serverId',
                      element: <ServerDisabled />
                    }
                  ]
                }
              ]
            },
            {
              path: 'pf',
              children: [
                {
                  path: 'manager',
                  element: <RequesterManager />,
                  children: [
                    {
                      path: 'show/:requesterId',
                      element: <RequesterTabDetails />
                    }
                  ]
                }
              ]
            },
            {
              path: 'pj',
              children: [
                {
                  path: 'manager',
                  element: <RequesterPjManager />,
                  children: [
                    {
                      path: 'show/:requesterId',
                      element: <RequesterPjTabDetails />
                    }
                  ]
                }
              ]
            },
            {
              path: 'support/new',
              element: <SupportCreate />
            },
            {
              path: 'server/new',
              element: <ServerCreate />
            }
          ]
        },
        {
          path: 'organization',
          children: [
            {
              path: 'account',
              element: <OrganizationProfile />,
              children: [
                {
                  path: 'details',
                  element: <OrganizationTabDetails />
                },
                {
                  path: 'edit',
                  element: <OrganizationTabEdit />
                }
              ]
            },
            {
              path: 'manager',
              element: <OrganogramManager />,
              children: [
                {
                  path: 'organogram',
                  element: <Organogram />
                }
              ]
            }
          ]
        },
        {
          path: 'user',
          element: <UserProfile />,
          children: [
            {
              path: 'personal-pf',
              element: <UserTabPersonal />
            },
            {
              path: 'personal-pj',
              element: <UserTabPj />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            },
            {
              path: 'settings',
              element: <UserTabSettings />
            },
            {
              path: 'certificate',
              element: <UserTabCertificate />
            }
          ]
        },
        {
          path: 'process',
          children: [
            {
              path: 'new',
              element: <ProcessCreate />
            },
            {
              path: 'show/:processId',
              element: <ProcessShow />
            },
            {
              path: 'department',
              element: <ProcessDepartment />,
              children: [
                {
                  path: 'in-progress',
                  element: <ProcessDepartmentInProgress />
                },
                {
                  path: 'to-sign',
                  element: <ProcessDepartmentToSign />
                },
                {
                  path: 'sent',
                  element: <ProcessDepartmentSent />
                },
                {
                  path: 'favorites',
                  element: <ProcessDepartmentFavorites />
                },
                {
                  path: 'archived',
                  element: <ProcessDepartmentArchived />
                },
                {
                  path: 'deadline-control',
                  element: <ProcessDepartmentDeadlineControl />
                }
              ]
            },
            {
              path: 'in-my-care',
              element: <ProcessInMyCare />,
              children: [
                {
                  path: 'in-progress',
                  element: <ProcessInMyCareInProgress />
                },
                {
                  path: 'to-sign',
                  element: <ProcessInMyCareToSign />
                },
                {
                  path: 'sent',
                  element: <ProcessInMyCareSent />
                },
                {
                  path: 'favorites',
                  element: <ProcessInMyCareFavorites />
                },
                {
                  path: 'archived',
                  element: <ProcessInMyCareArchived />
                },
                {
                  path: 'deadline-control',
                  element: <ProcessInMyCareDeadlineControl />
                }
              ]
            },
            {
              path: 'my',
              element: <ProcessMy />,
              children: [
                {
                  path: 'in-progress',
                  element: <ProcessMyInProgress />
                },
                {
                  path: 'to-sign',
                  element: <ProcessMyToSign />
                },
                {
                  path: 'deadline-control',
                  element: <ProcessMyDeadlineControl />
                },
                {
                  path: 'favorites',
                  element: <ProcessMyFavorites />
                },
                {
                  path: 'finished',
                  element: <ProcessMyFinished />
                }
              ]
            },
            {
              path: 'requester',
              children: [
                {
                  path: ':path',
                  element: <ProcessRequester />,
                  children: [
                    {
                      path: 'in-progress',
                      element: <ProcessRequesterInProgress />
                    },
                    {
                      path: 'to-sign',
                      element: <ProcessRequesterToSign />
                    },
                    {
                      path: 'deadline-control',
                      element: <ProcessRequesterDeadlineControl />
                    },
                    {
                      path: 'favorites',
                      element: <ProcessRequesterFavorites />
                    },
                    {
                      path: 'finished',
                      element: <ProcessRequesterFinished />
                    }
                  ]
                }
              ]
            },
            {
              path: 'server',
              children: [
                {
                  path: ':path',
                  element: <ProcessDepartmentModule />,
                  children: [
                    {
                      path: 'in-progress',
                      element: <ProcessDepartmentModuleInProgress />
                    },
                    {
                      path: 'to-sign',
                      element: <ProcessDepartmentModuleToSign />
                    },
                    {
                      path: 'deadline-control',
                      element: <ProcessDepartmentModuleDeadlineControl />
                    },
                    {
                      path: 'sent',
                      element: <ProcessDepartmentModuleSent />
                    },
                    {
                      path: 'favorites',
                      element: <ProcessDepartmentModuleFavorites />
                    },
                    {
                      path: 'archived',
                      element: <ProcessDepartmentModuleArchived />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'subjects',
          children: [
            {
              path: 'new',
              element: <SubjectCreate />
            },
            {
              path: 'list',
              element: <SubjectList />
            },
            {
              path: 'manager',
              element: <SubjectManager />,
              children: [
                {
                  path: 'show/:subjectId',
                  element: <SubjectTabDetails />
                },
                {
                  path: 'edit/:subjectId',
                  element: <SubjectTabEdit />
                },
                {
                  path: 'mandatory-docs/:subjectId',
                  element: <SubjectTabMandatoryDocs />
                }
              ]
            }
          ]
        },
        {
          path: 'mandatory-docs',
          children: [
            {
              path: 'new',
              element: <MandatoryDocCreate />
            },
            {
              path: 'list',
              element: <MandatoryDocList />
            },
            {
              path: 'manager',
              element: <MandatoryDocManager />,
              children: [
                {
                  path: 'show/:mandatoryDocId',
                  element: <MandatoryDocTabDetails />
                },
                {
                  path: 'edit/:mandatoryDocId',
                  element: <MandatoryDocTabEdit />
                },
                {
                  path: 'subjects/:mandatoryDocId',
                  element: <MandatoryDocTabSubjects />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
