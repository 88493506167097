import React, { createContext, useEffect, useReducer } from 'react';
import { SETDEPARTMENT } from 'store/reducers/actions';
import currentDepartmentReducer from 'store/reducers/currentDepartment';
import { CurrentDepartmentConfig, CurrentDepartmentContextType } from 'types/currentDepartment';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import { Department } from 'types/department';
import useOrganization from 'hooks/useOrganization';

const initialState: CurrentDepartmentConfig = {
  instance: null
};

const CurrentDepartmentContext = createContext<CurrentDepartmentContextType | null>(null);

export const CurrentDepartmentProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(currentDepartmentReducer, initialState);
  const { person, isLoggedIn } = useAuth();
  const { organization } = useOrganization();
  const { current } = useAuthorization();

  const setCurrentDepartmentOnLoad = () => {
    if (current?.permission !== 'requester' && !!person && !!person.departments) {
      const current_user_department =
        person && person.departments.find(({ organization_id }: Department) => organization_id === Number(organization?.id));

      if (!!current_user_department?.id) {
        const instance = {
          id: current_user_department.id,
          name: `${current_user_department?.acronym} - ${current_user_department?.name}`
        };

        dispatch({
          type: SETDEPARTMENT,
          payload: {
            instance
          }
        });
      } else if (current?.permission === 'support' && !!organization) {
        const instance = {
          id: Number(organization?.main_department),
          name: `${organization?.acronym} - ${organization?.name}`
        };

        dispatch({
          type: SETDEPARTMENT,
          payload: {
            instance
          }
        });
      }
    }
  };

  const setCurrentDepartment = (id: number, name: string) => {
    const instance = {
      id: id,
      name: name
    };

    dispatch({
      type: SETDEPARTMENT,
      payload: {
        instance
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn === true && current?.permission !== 'requester') {
      setCurrentDepartment(0, '');
      setCurrentDepartmentOnLoad();
    } else {
      setCurrentDepartment(0, '');
    }
  }, [current, isLoggedIn]);

  return (
    <CurrentDepartmentContext.Provider value={{ ...state, setCurrentDepartment, setCurrentDepartmentOnLoad }}>
      {children}
    </CurrentDepartmentContext.Provider>
  );
};

export default CurrentDepartmentContext;
