import * as Sentry from '@sentry/react';

const initSentry = (): void => {
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
  const isStaging = process.env.REACT_APP_NODE_ENV === 'staging';
  const urlBackend = process.env.REACT_APP_URL_BACKEND || 'https://api.0paper.com.br';

  if (isProduction || isStaging) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_NODE_ENV,
      // integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: isProduction ? 0.1 : 0.5,
      replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
      profilesSampleRate: isProduction ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [urlBackend],
      enabled: true
    });
  }
};

export default initSentry;
