import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator, closeToast } from 'utils/ToastNotistack';
import { CloseOutlined } from '@ant-design/icons';
import { IconButton } from '@mui/material';

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.main
  },
  '&.SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.main
  },
  '&.SnackbarItem-variant': {
    backgroundColor: theme.palette.success.main
  },
  '&.SnackbarItem-variantInfo': {
    backgroundColor: theme.palette.info.main
  },
  '&.SnackbarItem-variantWarning': {
    backgroundColor: theme.palette.warning.light
  }
}));

const Notistack = ({ children }: any) => {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      dense={false}
      hideIconVariant={false}
      autoHideDuration={5000}
      action={() => (
        <>
          <IconButton size="small" color="inherit" onClick={() => closeToast()}>
            <CloseOutlined />
          </IconButton>
        </>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </StyledSnackbarProvider>
  );
};

export default Notistack;
