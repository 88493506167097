import { CAPSULE } from './actions';
import { CapsuleConfig, CapsuleActionProps } from 'types/capsule';
export const initialState: CapsuleConfig = {
  capsules: null
};

const capsuleConfig = (state = initialState, action: CapsuleActionProps) => {
  switch (action.type) {
    case CAPSULE: {
      const { capsules } = action.payload!;
      return {
        ...state,
        capsules
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default capsuleConfig;
