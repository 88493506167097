const PermissionIsSupportComponentHidden = (permission: String | undefined) => {
  return permission === 'support';
};

const PermissionIsAdminOrSupportComponentHidden = (permission: String | undefined) => {
  return permission === 'support' || permission === 'admin';
};

const PermissionIsLv1ComponentHidden = (permission: String | undefined) => {
  return permission === 'support' || permission === 'admin' || permission === 'server_lv1';
};

const PermissionIsServerComponentHidden = (permission: String | undefined) => {
  return (
    permission === 'support' ||
    permission === 'admin' ||
    permission === 'server_lv3' ||
    permission === 'server_lv2' ||
    permission === 'server_lv1'
  );
};

const PermissionIsSupportPageLoad = (permission: String | undefined) => {
  return permission === 'support' || permission === undefined || permission === null;
};

const PermissionIsAdminOrSupportPageLoad = (permission: String | undefined) => {
  return permission === 'support' || permission === 'admin' || permission === undefined || permission === null;
};

const PermissionIsLv1PageLoad = (permission: String | undefined) => {
  return (
    permission === 'support' || permission === 'admin' || permission === 'server_lv1' || permission === undefined || permission === null
  );
};

export default PermissionIsAdminOrSupportComponentHidden;
export { PermissionIsServerComponentHidden };
export { PermissionIsSupportComponentHidden };
export { PermissionIsLv1ComponentHidden };
export { PermissionIsLv1PageLoad };
export { PermissionIsAdminOrSupportPageLoad };
export { PermissionIsSupportPageLoad };
