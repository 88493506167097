import { useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { EditOutlined, SettingOutlined, LogoutOutlined, SafetyCertificateOutlined, AuditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(9);
  const handleListItemClick = (index: number, route: string) => {
    setSelectedIndex(index);
    navigate(route);
  };

  const { person } = useAuth();
  const navigate = useNavigate();

  const routePfOrPj = () => {
    if (person?.user?.personable_type === 'PhysicalPerson') {
      return '/user/personal-pf';
    } else {
      return '/user/personal-pj';
    }
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={() => handleListItemClick(0, routePfOrPj())}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Editar Perfil" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 1} onClick={() => handleListItemClick(1, '/user/password')}>
        <ListItemIcon>
          <SafetyCertificateOutlined />
        </ListItemIcon>
        <ListItemText primary="Segurança" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={() => handleListItemClick(2, '/user/settings')}>
        <ListItemIcon>
          <SettingOutlined />
        </ListItemIcon>
        <ListItemText primary="Configurações" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 4} onClick={() => handleListItemClick(4, '/user/certificate')}>
        <ListItemIcon>
          <AuditOutlined />
        </ListItemIcon>
        <ListItemText primary="Certificado ICP-Brasil" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
