import { useContext } from 'react';
import OrganizationContext from 'contexts/OrganizationContext';

const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useOrganization;
