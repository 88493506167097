import { SETDEPARTMENT } from './actions';
import { CurrentDepartmentConfig, CurrentDepartmentActionProps } from 'types/currentDepartment';
export const initialState: CurrentDepartmentConfig = {
  instance: null
};

const currentDepartment = (state = initialState, action: CurrentDepartmentActionProps) => {
  switch (action.type) {
    case SETDEPARTMENT: {
      const { instance } = action.payload!;
      return {
        ...state,
        instance
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default currentDepartment;
