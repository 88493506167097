import { Button, Container, Grid, Typography } from '@mui/material';
import useOrganization from 'hooks/useOrganization';
import { useState, useEffect } from 'react';
import axiosServices from 'utils/axios';
import TreeView from '@mui/lab/TreeView';
import toast from 'utils/ToastNotistack';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
import { TransitionProps } from '@mui/material/transitions';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import AnimateButton from 'components/@extended/AnimateButton';
import MainCard from 'components/MainCard';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)'
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
    }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}));

export type OrganizationTree = {
  id: string;
  name: string;
  acronym: string;
  subtree_ids: Array<string>;
  children: Array<OrganizationTree>;
};

const OrganogramPublic = () => {
  const [department, setDepartment] = useState<OrganizationTree>();
  const [subtreeIds, setSubtreeIds] = useState<Array<string>>(['']);
  const { organization } = useOrganization();

  const getDepartments = async () => {
    try {
      const response = await axiosServices.get(`v1/organizations/${organization?.id}/organogram`);
      setDepartment(response.data[0]);
      setSubtreeIds([response.data[0].id.toString()]);
    } catch (error) {
      toast('É necessário cadastrar o departamento principal da organização.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const TreeRender = (department: OrganizationTree) => {
    const isChildren = department.children && department.children.length > 0;
    if (isChildren) {
      return (
        <StyledTreeItem key={department.id} nodeId={department.id.toString()} label={`${department.acronym}  -  ${department.name}`}>
          {department.children.map((node) => TreeRender(node))}
        </StyledTreeItem>
      );
    }
    return (
      <StyledTreeItem
        key={department.id}
        nodeId={department?.id.toString()}
        label={`${department.acronym}  -  ${department.name}`}
      ></StyledTreeItem>
    );
  };

  const handleClick = () => {
    if (!!department && subtreeIds[subtreeIds.length - 1] === department?.id.toString()) {
      setSubtreeIds([...department.subtree_ids]);
    } else {
      setSubtreeIds([department?.id.toString() || '']);
    }
  };

  return (
    <>
      {department && (
        <Container>
          <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: { md: 5, xs: 2.5 }, mb: 4 }}>
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="center" sx={{ mb: 1, textAlign: 'center' }}>
                <Grid item sm={12} md={12}>
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h2" sx={{ mb: 1 }}>
                        Organograma
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Esse é uma visão detalhada da estrutura organizacional da instituição.</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} xl={12}>
              <MainCard boxShadow>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid item md={1} xs={12}>
                      <AnimateButton>
                        <Button
                          variant="text"
                          startIcon={subtreeIds[subtreeIds.length - 1] !== department?.id.toString() ? <MinusOutlined /> : <PlusOutlined />}
                          size="small"
                          color="primary"
                          onClick={handleClick}
                        >
                          {subtreeIds[subtreeIds.length - 1] !== department?.id.toString() ? 'Recolher' : 'Expandir'}
                        </Button>
                      </AnimateButton>
                    </Grid>
                    <TreeView
                      aria-label="customized"
                      expanded={subtreeIds}
                      onNodeSelect={(event: any, nodeId: any) => {
                        const index = subtreeIds.indexOf(nodeId);
                        const copyExpanded = [...subtreeIds];
                        if (index === -1) {
                          copyExpanded.push(nodeId);
                        } else {
                          copyExpanded.splice(index, 1);
                        }
                        setSubtreeIds(copyExpanded);
                      }}
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare />}
                      defaultEndIcon={<CloseSquare />}
                    >
                      {department && TreeRender(department)}
                    </TreeView>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default OrganogramPublic;
