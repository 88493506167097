import axios from 'utils/axios';
import React, { createContext, useEffect, useReducer } from 'react';
import { CHECK } from 'store/reducers/actions';
import authorizationReducer from 'store/reducers/authorization';
import { AuthorizationConfig, AuthorizationContextType } from 'types/authorization';
import useOrganization from 'hooks/useOrganization';
import useAuth from 'hooks/useAuth';

const initialState: AuthorizationConfig = {
  current: null
};

const AuthorizationContext = createContext<AuthorizationContextType | null>(null);

export const AuthorizationProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authorizationReducer, initialState);
  const { isLoggedIn } = useAuth();
  const { organization } = useOrganization();

  const setCurrentPermission = async () => {
    const payloadCurrentPermission: AuthorizationConfig = {
      current: {
        id: '0',
        permission: 'requester'
      }
    };

    if (isLoggedIn === true && organization !== null) {
      try {
        const response = await axios.get(`v1/organizations/${organization?.id}/organization_users/check`);
        const current = response.data;

        dispatch({
          type: CHECK,
          payload: {
            current
          }
        });

        return current;
      } catch (error) {
        dispatch({
          type: CHECK,
          payload: payloadCurrentPermission
        });
      }
    } else {
      dispatch({
        type: CHECK,
        payload: payloadCurrentPermission
      });
    }
  };

  const deleteCurrentPermission = () => {
    dispatch({
      type: CHECK,
      payload: {
        current: null
      }
    });
  };

  useEffect(() => {
    setCurrentPermission();
  }, [isLoggedIn, organization]);

  return (
    <AuthorizationContext.Provider value={{ ...state, setCurrentPermission, deleteCurrentPermission }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationContext;
