import { PersonProfile } from 'types/auth';

const getPersonName = (person: PersonProfile) => {
  if (person?.user?.personable_type === 'PhysicalPerson') {
    return person.name;
  } else {
    return person.corporate_name;
  }
};

const getPersonFunction = (person: PersonProfile) => {
  if (person?.user?.personable_type === 'PhysicalPerson') {
    return person.function;
  } else {
    return person.corporate_name;
  }
};

const getPersonPermission = (permission: String) => {
  switch (permission) {
    case 'support':
      return 'Suporte';
    case 'admin':
      return 'Administrador';
    case 'server_lv1':
      return 'Responsável pelo departamento';
    case 'server_lv2':
      return 'Operacional';
    case 'server_lv3':
      return 'Apenas Leitura';
    default:
      return 'Solicitante Externo';
  }
};

export { getPersonName, getPersonFunction, getPersonPermission };
