import { Grid, Typography, Container, Stack } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UploadSingleFile from 'components/third-party/dropzone/SingleFile';

const SignatureValidation = () => {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: { md: 5, xs: 2.5 }, mb: 4 }}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center" sx={{ mb: 1, textAlign: 'center' }}>
            <Grid item sm={12} md={12}>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ mb: 1 }}>
                    Serviço de validação de assinaturas eletrônicas
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph align="justify">
                    Submeta seu documento ao serviço oficial de validação de assinaturas eletrônicas da PublicSoft agora mesmo e verifique
                    de forma online e instantânea o status de assinaturas eletrônicas ICP-Brasil, GOV.BR, PublicSoft ou aquelas reconhecidas
                    por acordos internacionais. Atenda às suas necessidades de segurança e confiabilidade com rapidez e eficiência.
                  </Typography>

                  <Typography paragraph align="justify">
                    Ressaltamos que nenhuma informação ou arquivo é armazenado nos ambientes operacionais da PublicSoft. Os resultados da
                    validação têm o único propósito de identificar o titular do certificado digital utilizado e confirmar que o documento
                    assinado não sofreu alterações após a assinatura.
                  </Typography>

                  <Typography paragraph align="justify">
                    O serviço de validação de assinaturas eletrônicas da PublicSoft é gratuito e não requer cadastro. Basta submeter o
                    arquivo desejado e aguardar o resultado da validação. O processo é rápido e seguro.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Formik
                initialValues={{ files: null }}
                onSubmit={(values: any) => {
                  // submit form
                }}
                validationSchema={Yup.object().shape({
                  files: Yup.mixed().required('Avatar is a required.')
                })}
              >
                {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack spacing={1.5} alignItems="center">
                          <UploadSingleFile setFieldValue={setFieldValue} file={values.files} error={touched.files && !!errors.files} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignatureValidation;
