import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import OrganogramPublic from 'pages/public/OrganogramPublic';
import OrganizationDetails from 'pages/public/OrganizationDetails';
import SignatureValidation from 'pages/public/SignatureValidation';

const Dashboard = Loadable(lazy(() => import('pages/public/dashboard')));
const Select = Loadable(lazy(() => import('pages/public/select')));
const SetupOrganization = Loadable(lazy(() => import('pages/public/setup-organization')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

const PublicRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout layout={'landing'} />
        </GuestGuard>
      ),
      children: [
        {
          path: '*',
          element: <MaintenanceError />
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'dashboard',
          children: [
            {
              path: 'organogram',
              element: <OrganogramPublic />
            },
            {
              path: 'transparency',
              element: <Dashboard />
            },
            {
              path: 'organization',
              element: <OrganizationDetails />
            },
            {
              path: 'signature-validation',
              element: <SignatureValidation />
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout layout={'blank'} />
        </GuestGuard>
      ),
      children: [
        {
          path: 'organization-select',
          element: <Select />
        },
        {
          path: 'organization/:organizationId',
          element: <SetupOrganization />
        }
      ]
    }
  ]
};

export default PublicRoutes;
