import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import NavGroup from './NavGroup';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import NavigationRequester from '../../../../../menu-items/NavigationRequester';
import NavigationServer from '../../../../../menu-items/NavigationServer';
import { useState } from 'react';
import { PermissionIsServerComponentHidden } from 'utils/permissions';
import useAuthorization from 'hooks/useAuthorization';

const Navigation = ({ checkForScrollbar }: { checkForScrollbar: () => void }) => {
  const theme = useTheme();
  const { current } = useAuthorization();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const current_navigation = PermissionIsServerComponentHidden(current?.permission)
    ? NavigationServer(current?.permission)
    : NavigationRequester();

  let navigation_by_permissions;

  const navigation: NavItemType = {
    id: 'Navigation',
    title: 'Navegação',
    type: 'group',
    children: current_navigation
  };

  const menuItems: { items: NavItemType[] } = {
    items: [navigation]
  };

  navigation_by_permissions = menuItems;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = navigation_by_permissions.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  if (lastItem && lastItem < navigation_by_permissions.items.length) {
    lastItemId = navigation_by_permissions.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = navigation_by_permissions.items.slice(lastItem - 1, navigation_by_permissions.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = navigation_by_permissions.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
            checkForScrollbar={checkForScrollbar}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
