import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types/auth';
import { APP_DEFAULT_PATH } from 'config';
import useOrganization from 'hooks/useOrganization';

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { organization, setCurrentOrganization } = useOrganization();
  const LocalStorageOrganizationId = window.localStorage.getItem('0paper-current-organization');

  useEffect(() => {
    if (isLoggedIn) {
      navigate(APP_DEFAULT_PATH);
    } else if (organization && !LocalStorageOrganizationId) {
      setCurrentOrganization(organization.id);
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;
