import { CHECK } from './actions';
import { AuthorizationConfig, AuthorizationActionProps } from 'types/authorization';
export const initialState: AuthorizationConfig = {
  current: null
};

const authorization = (state = initialState, action: AuthorizationActionProps) => {
  switch (action.type) {
    case CHECK: {
      const { current } = action.payload!;
      return {
        ...state,
        current
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default authorization;
