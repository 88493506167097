import { DefaultConfigProps } from 'types/config';

export const APP_DEFAULT_PATH = `/process/department/in-progress`;
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;

const config: DefaultConfigProps = {
  fontFamily: `'Public Sans', sans-serif`,
  menuOrientation: 'vertical',
  miniDrawer: false,
  container: false,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
