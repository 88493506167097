import {
  AppstoreOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  ClusterOutlined,
  CodepenOutlined,
  GoldOutlined,
  HomeOutlined,
  MailOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ContainerOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import useCapsule from 'hooks/useCapsule';
import iconSelected from 'utils/icon';

const NavigationServer = (permission: String | undefined) => {
  const { capsules } = useCapsule();

  let menuItems: any[] | undefined;

  if (capsules !== null && capsules !== undefined) {
    menuItems = capsules.map((c) => ({
      id: c.url,
      title: c.name,
      type: 'item',
      url: `process/server/${c.url}/in-progress`,
      icon: iconSelected(c.icon)
    }));
  } else {
    menuItems = [];
  }

  if (menuItems.length > 3) {
    menuItems = [
      {
        id: 'structureCapsule',
        title: 'Módulos',
        type: 'collapse',
        icon: CodepenOutlined,
        children: menuItems
      }
    ];
  }

  const requesterArrayStart = [
    {
      id: 'process',
      title: 'Processos',
      type: 'collapse',
      icon: MailOutlined,
      children: [
        {
          id: 'process-department',
          title: 'Departamento',
          type: 'item',
          url: 'process/department/in-progress',
          icon: AppstoreOutlined
        },
        {
          id: 'process-department-ac',
          title: 'Aos meus cuidados',
          type: 'item',
          url: 'process/in-my-care/in-progress',
          icon: AppstoreAddOutlined
        },
        {
          id: 'process/my/in-progress',
          title: 'Meus processos',
          type: 'item',
          url: 'process/my/in-progress',
          icon: UserOutlined
        }
      ]
    }
  ];

  let requesterArrayEnd;

  if (permission === 'support') {
    requesterArrayEnd = [
      {
        id: 'structure',
        title: 'Estrutura',
        type: 'collapse',
        icon: BankOutlined,
        children: [
          {
            id: 'capsule',
            title: 'Módulos',
            type: 'item',
            url: 'modules/list',
            icon: CodepenOutlined
          },
          {
            id: 'organization',
            title: 'Organização',
            type: 'item',
            url: 'organization/account/details',
            icon: HomeOutlined
          },
          {
            id: 'departments',
            title: 'Departamentos',
            type: 'item',
            url: 'departments/list/actived',
            icon: GoldOutlined
          },
          {
            id: 'organogram',
            title: 'Organograma',
            type: 'item',
            url: 'organization/manager/organogram',
            icon: ClusterOutlined
          },
          {
            id: 'users',
            title: 'Usuários',
            type: 'item',
            url: 'users/servers',
            icon: UsergroupAddOutlined
          },
          {
            id: 'subjects',
            title: 'Assuntos',
            type: 'item',
            url: 'subjects/list',
            icon: ContainerOutlined
          },
          {
            id: 'mandatory-docs',
            title: 'Anexos',
            type: 'item',
            url: 'mandatory-docs/list',
            icon: PaperClipOutlined
          }
        ]
      }
    ];
  } else {
    requesterArrayEnd = [
      {
        id: 'structure',
        title: 'Estrutura',
        type: 'collapse',
        icon: BankOutlined,
        children: [
          {
            id: 'organization',
            title: 'Organização',
            type: 'item',
            url: 'organization/account/details',
            icon: HomeOutlined
          },
          {
            id: 'departments',
            title: 'Departamentos',
            type: 'item',
            url: 'departments/list/actived',
            icon: GoldOutlined
          },
          {
            id: 'organogram',
            title: 'Organograma',
            type: 'item',
            url: 'organization/manager/organogram',
            icon: ClusterOutlined
          },
          {
            id: 'users',
            title: 'Usuários',
            type: 'item',
            url: 'users/servers',
            icon: UsergroupAddOutlined
          },
          {
            id: 'subjects',
            title: 'Assuntos',
            type: 'item',
            url: 'subjects/list',
            icon: ContainerOutlined
          },
          {
            id: 'mandatory-docs',
            title: 'Anexos',
            type: 'item',
            url: 'mandatory-docs/list',
            icon: PaperClipOutlined
          }
        ]
      }
    ];
  }

  let requesterArrayFinish;

  if (menuItems && menuItems.length > 0) {
    requesterArrayFinish = requesterArrayStart.concat(menuItems, requesterArrayEnd);
  } else {
    requesterArrayFinish = requesterArrayStart.concat(requesterArrayEnd);
  }

  return requesterArrayFinish;
};

export default NavigationServer;
