import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types/auth';
import useOrganization from 'hooks/useOrganization';

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, logout } = useAuth();
  const { organization, setCurrentOrganization, deleteCurrentOrganization } = useOrganization();
  const LocalStorageOrganizationId = window.localStorage.getItem('0paper-current-organization');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && !organization && !LocalStorageOrganizationId) {
      deleteCurrentOrganization();
    } else if (isLoggedIn && organization && !LocalStorageOrganizationId) {
      setCurrentOrganization(organization.id);
    } else if (isLoggedIn && !organization && !LocalStorageOrganizationId) {
      logout();
    } else if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
