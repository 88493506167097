import { Theme } from '@mui/material/styles';
import { useMediaQuery, Grid, List, ListItem, Stack, Typography, Link, Container } from '@mui/material';
import NumberFormat from 'react-number-format';
import MainCard from 'components/MainCard';
import useOrganization from 'hooks/useOrganization';

const OrganizationDetails = () => {
  const matchDownMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { organization } = useOrganization();

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: { md: 5, xs: 2.5 }, mb: 4 }}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center" sx={{ mb: 1, textAlign: 'center' }}>
            <Grid item sm={12} md={12}>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ mb: 1 }}>
                    Instituição
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Informações públicas da instituição</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MainCard title="Organização">
                <List sx={{ py: 0 }}>
                  <ListItem divider>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Razão Social</Typography>
                          <Typography>{organization?.name}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Sigla</Typography>
                          <Typography>{organization?.acronym?.toUpperCase()}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Telefone</Typography>
                          <Typography>
                            <NumberFormat value={organization?.phone} displayType="text" type="text" format="## # #### ####" />
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">CNPJ</Typography>
                          <NumberFormat value={organization?.cnpj} displayType="text" type="text" format="##.###.###/####-##" />
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Site</Typography>
                          <Link href={organization?.site} target="_blank">
                            {organization?.site}
                          </Link>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Horário de encerramento</Typography>
                          <Typography>{`${organization?.end_hour}:00`}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
            <Grid item xs={12}>
              <MainCard title="Endereço">
                <List sx={{ py: 0 }}>
                  <ListItem divider>
                    <Grid container spacing={matchDownMD ? 0.5 : 3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">CEP</Typography>
                          <NumberFormat value={organization?.address?.zipcode} displayType="text" type="text" format="#####-###" />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Rua</Typography>
                          <Typography>{organization?.address?.street}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider>
                    <Grid container spacing={matchDownMD ? 0.5 : 3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Número</Typography>
                          <Typography>{organization?.address?.number}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Bairro</Typography>
                          <Typography>{organization?.address?.neighborhood}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider>
                    <Grid container spacing={matchDownMD ? 0.5 : 3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Cidade</Typography>
                          <Typography>{organization?.address?.city}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Estado</Typography>
                          <Typography>{organization?.address?.state}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={matchDownMD ? 0.5 : 3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Complemento</Typography>
                          <Typography>{organization?.address?.complement}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrganizationDetails;
