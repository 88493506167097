import { useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MessageOutlined, GiftOutlined, MedicineBoxOutlined, LogoutOutlined, SyncOutlined } from '@ant-design/icons';
import useOrganization from 'hooks/useOrganization';
interface Props {
  handleLogout: () => void;
}

const SettingTab = ({ handleLogout }: Props) => {
  const { deleteCurrentOrganization } = useOrganization();
  const [selectedIndex, setSelectedIndex] = useState(9);
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
  };

  const changeOrganization = () => {
    deleteCurrentOrganization();
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <GiftOutlined />
        </ListItemIcon>
        <ListItemText primary="Novidades" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <MedicineBoxOutlined />
        </ListItemIcon>
        <ListItemText primary="Central de Ajuda" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <MessageOutlined />
        </ListItemIcon>
        <ListItemText primary="Chat com Suporte" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={changeOrganization}>
        <ListItemIcon>
          <SyncOutlined />
        </ListItemIcon>
        <ListItemText primary="Acessar outra Organização" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
