import React, { createContext, useEffect, useReducer } from 'react';
import axiosServices from 'utils/axios';
import useOrganization from 'hooks/useOrganization';
import { CapsuleConfig, CapsuleContextType } from 'types/capsule';
import { CAPSULE } from 'store/reducers/actions';
import capsuleReducer from 'store/reducers/capsule';

const initialState: CapsuleConfig = {
  capsules: null
};

const CapsuleContext = createContext<CapsuleContextType | null>(null);

export const CapsuleProvider = ({ children }: { children: React.ReactElement }) => {
  const { organization } = useOrganization();
  const [state, dispatch] = useReducer(capsuleReducer, initialState);

  const setCurrentCapsules = async () => {
    if (organization) {
      try {
        const response = await axiosServices.get(`v1/organizations/${organization?.id}/capsules?items=100`);
        const capsules = response.data.data;

        dispatch({
          type: CAPSULE,
          payload: {
            capsules
          }
        });

        return capsules;
      } catch (error) {}
    }
  };

  useEffect(() => {
    setCurrentCapsules();
  }, [organization]);

  return <CapsuleContext.Provider value={{ ...state, setCurrentCapsules }}>{children}</CapsuleContext.Provider>;
};

export default CapsuleContext;
