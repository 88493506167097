import axios from 'utils/axios';
import React, { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { SETUP } from 'store/reducers/actions';
import organizationReducer from 'store/reducers/organization';
import { OrganizationConfig, OrganizationContextType } from 'types/organization';
import useConfig from 'hooks/useConfig';

const initialState: OrganizationConfig = {
  organization: null
};

const OrganizationContext = createContext<OrganizationContextType | null>(null);

export const OrganizationProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(organizationReducer, initialState);
  const UrlOrganizationId = window.location.pathname.split('/')[2];
  const LocalStorageOrganizationId = window.localStorage.getItem('0paper-current-organization');
  const navigate = useNavigate();
  const { onChangePresetColor } = useConfig();

  const setCurrentOrganization = async (organizationId: string = UrlOrganizationId) => {
    try {
      const response = await axios.get(`v1/organizations/${organizationId}`);
      const organization = response.data;
      dispatch({
        type: SETUP,
        payload: {
          organization
        }
      });

      window.localStorage.setItem('0paper-current-organization', JSON.stringify(organization.id));

      onChangePresetColor(organization?.thema);

      return organization;
    } catch (error) {
      navigate('/organization-select');
    }
  };

  const deleteCurrentOrganization = () => {
    window.localStorage.removeItem('0paper-current-organization');
    window.localStorage.removeItem('0paper-auth-headers');

    dispatch({
      type: SETUP,
      payload: {
        organization: null
      }
    });
    navigate('/organization-select');
    onChangePresetColor('default');
  };

  useEffect(() => {
    const regex = /\/organization\/\d/;
    const found = window.location.pathname.match(regex);
    if (found) {
      setCurrentOrganization();
      navigate('/dashboard');
    } else if (LocalStorageOrganizationId) {
      setCurrentOrganization(LocalStorageOrganizationId);
    } else if (!found && !LocalStorageOrganizationId) {
      navigate('/organization-select');
    }
  }, []);

  return (
    <OrganizationContext.Provider value={{ ...state, setCurrentOrganization, deleteCurrentOrganization }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContext;
