import { combineReducers } from 'redux';
import menu from './menu';
import organization from './organization';
import auth from './auth';
import capulse from './capsule';
import currentDepartment from './currentDepartment';

const reducers = combineReducers({
  menu,
  organization,
  auth,
  capulse,
  currentDepartment
});

export default reducers;
