import {
  FileSearchOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  AuditOutlined,
  ProfileOutlined,
  EditOutlined,
  SearchOutlined,
  InboxOutlined,
  LaptopOutlined,
  FlagOutlined,
  CommentOutlined,
  NotificationOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  ExceptionOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';

const icons = [
  ['FileSearchOutlined', FileSearchOutlined],
  ['FileDoneOutlined', FileDoneOutlined],
  ['FileSyncOutlined', FileSyncOutlined],
  ['FileTextOutlined', FileTextOutlined],
  ['AuditOutlined', AuditOutlined],
  ['ProfileOutlined', ProfileOutlined],
  ['SearchOutlined', SearchOutlined],
  ['InboxOutlined', InboxOutlined],
  ['LaptopOutlined', LaptopOutlined],
  ['FlagOutlined', FlagOutlined],
  ['CommentOutlined', CommentOutlined],
  ['NotificationOutlined', NotificationOutlined],
  ['FileAddOutlined', FileAddOutlined],
  ['FileProtectOutlined', FileProtectOutlined],
  ['ExceptionOutlined', ExceptionOutlined],
  ['ArrowLeftOutlined', ArrowLeftOutlined]
];

const iconsList = [
  'FileSearchOutlined',
  'FileDoneOutlined',
  'FileSyncOutlined',
  'FileTextOutlined',
  'AuditOutlined',
  'ProfileOutlined',
  'SearchOutlined',
  'InboxOutlined',
  'LaptopOutlined',
  'FlagOutlined',
  'CommentOutlined',
  'NotificationOutlined',
  'FileAddOutlined',
  'FileProtectOutlined',
  'ExceptionOutlined',
  'ArrowLeftOutlined'
];

const iconSelected = (icon: any) => {
  // eslint-disable-next-line array-callback-return
  const icon_selected = icons.find(function (item) {
    if (item[0] === icon) {
      return true;
    }
  });

  return icon_selected ? icon_selected[1] : EditOutlined;
};

export default iconSelected;
export { iconsList };
